import { createHashRouter } from 'react-router-dom';
import App from '../App';
import Index from '../components/Index';
import Case from '../components/case/index';
function isMobileDevice() {
  return [
    {
      index: true,
      element: <Index />,
    },
    {
      path: '/index/:name',
      element: <Index />,
    },
    {
      path: '/index',
      element: <Index />,
    },
    {
      path: '/case',
      element: <Case />,
    },
  ];
}

const route = createHashRouter([
  {
    path: '/',
    element: <App />,
    children: isMobileDevice(),
  },
]);
export default route;
