import './style.scss';
import { useEffect } from 'react';

function CaseText() {
  useEffect(() => {}, []);
  const textList = [
    {
      title: '从创意构想到落地执行',
      text: '借助AIGC技术，我们为品牌提供全链路创意到执行的智能持，高效推动创意转化，快速落地并优化营销效果',
      bg: 'https://bimoai-sh.oss-cn-shanghai.aliyuncs.com/bimoai/console/static/webui/web/zuoshenme01.png',
    },
    {
      title: '快速增长 裂变促活',
      text: '凭借创意多样的AIGC互动玩法，独特的互动体验不断吸引用参与，助力品牌实现快速裂变与持续活跃。',
      bg: 'https://bimoai-sh.oss-cn-shanghai.aliyuncs.com/bimoai/console/static/webui/web/zuoshenme02.png',
    },
    {
      title: '融合线上线下多元场景',
      text: '将AIGC与NFC技术的深度融合，结合线下路演活动，通NFC“碰一碰”，实现多元化场景的互动',
      bg: 'https://bimoai-sh.oss-cn-shanghai.aliyuncs.com/bimoai/console/static/webui/web/zuoshenme03.png',
    },
    {
      title: '智能数据洞察',
      text: '实时监测活动全程数据动态，为运营策略提供精准支持，助力优化决策与提升效果',
      bg: 'https://bimoai-sh.oss-cn-shanghai.aliyuncs.com/bimoai/console/static/webui/web/zuoshenme04.png',
    },
  ];

  return (
    <div className={`homewrapt`}>
      <div className="home">
        <div className="h-t">一站式AI互动营销，全方位助力品牌增长</div>
        <div className="h-t2">
          整合AI与互动体验，从创意到增长，笔墨AI为您的营销全程赋能
        </div>
        <div className="h-c">
          {textList.map((item, index) => (
            <div className="t-1 t-t" key={index}>
              <div className="bg-u"></div>
              <div
                className="bg-t"
                style={{ backgroundImage: `url(${item.bg})` }}
              ></div>
              <div className="t-t-th">{item.title}</div>
              <div className="t-t-td">{item.text}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default CaseText;
