import React from 'react';
import ReactDOM from 'react-dom/client';
import './static/font/iconfont.css'
import {RouterProvider} from 'react-router-dom';
import router from './router/index'
if (window.CONFIG.openGrey === true) {
    document.querySelector("html").style.filter = "grayscale(100%)"
}
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <RouterProvider router={router}/>
);

