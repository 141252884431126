import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

// function getRandomValue() {
//   return Math.floor(Math.random() * 2) + 1;
// }

function animation() {
  gsap.registerPlugin(ScrollTrigger);
  // Home的动画
  gsap.fromTo(
    '.home .aniText',
    {
      y: 200,
      opacity: 0,
    },
    {
      y: 0,
      opacity: 1,
      duration: 1,
    }
  );
  //     视频合集
  gsap.fromTo(
    '.top-video',
    { x: 300, opacity: 0 },
    {
      x: 0,
      opacity: 1,
      duration: 0.8,
      scrollTrigger: {
        trigger: '.top-video',
        start: 'top 90%',
        toggleActions: 'play none none none',
      },
    }
  );
  gsap.fromTo(
    '.center-left-video',
    { x: -300, opacity: 0 },
    {
      x: 0,
      opacity: 1,
      duration: 0.8,
      scrollTrigger: {
        trigger: '.center-left-video',
        start: 'top 90%',
        toggleActions: 'play none none none',
      },
    }
  );
  gsap.fromTo(
    '.center-right-video',
    { x: 300, opacity: 0 },
    {
      x: 0,
      opacity: 1,
      duration: 0.8,
      scrollTrigger: {
        trigger: '.center-right-video',
        start: 'top 90%',
        toggleActions: 'play none none none',
      },
    }
  );
  gsap.fromTo(
    '.bottom-left-video',
    { x: -300, opacity: 0 },
    {
      x: 0,
      opacity: 1,
      duration: 0.8,
      scrollTrigger: {
        trigger: '.bottom-left-video',
        start: 'top 90%',
        toggleActions: 'play none none none',
      },
    }
  );
  gsap.fromTo(
    '.bottom-right-video',
    { x: 300, opacity: 0 },
    {
      x: 0,
      opacity: 1,
      duration: 0.8,
      scrollTrigger: {
        trigger: '.bottom-right-video',
        start: 'top 90%',
        toggleActions: 'play none none none',
      },
    }
  );
  //     ai音乐
  let aiMusic = gsap.timeline({
    scrollTrigger: {
      trigger: '.aimusic',
      start: 'top bottom',
      toggleActions: 'play none none none',
    },
  });
  aiMusic
    .fromTo(
      '.aimusic .aniText',
      {
        y: 200,
        opacity: 0,
      },
      {
        y: 0,
        opacity: 1,
        duration: 1,
      },
      0
    )
    .fromTo(
      '.aimusic .video',
      {
        x: 600,
        opacity: 0,
        rotation: 90,
      },
      {
        x: 0,
        opacity: 1,
        duration: 1,
        rotation: 0,
      },
      0.5
    );
  //     我们做什么
  gsap.fromTo(
    '.dowhat .able>div',
    { y: 400, opacity: 0 },
    {
      opacity: 1,
      y: 0,
      duration: 0.8,
      stagger: 0.3,
      scrollTrigger: {
        trigger: '.dowhat .able>div',
        start: '-=400 bottom',
        toggleActions: 'play none none none',
      },
    }
  );
  // 写真页
  let aiPhoto = gsap.timeline({
    scrollTrigger: {
      trigger: '.aiphoto',
      start: 'top bottom',
      toggleActions: 'play none none none',
    },
  });
  aiPhoto
    .fromTo(
      '.aiphoto .aniText',
      {
        y: 200,
        opacity: 0,
      },
      {
        y: 0,
        opacity: 1,
        duration: 1,
      },
      0
    )
    .fromTo(
      '.aiphoto .m-imgs',
      {
        y: 400,
        opacity: 0,
      },
      {
        y: 0,
        opacity: 1,
        duration: 1,
      },
      0.5
    );
  //     手机版ai视频视频列表
  let aivideo = gsap.timeline({
    scrollTrigger: {
      trigger: '.aivideo',
      start: 'top bottom',
      toggleActions: 'play none none none',
    },
  });
  aivideo
    .fromTo(
      '.aivideo .aniText',
      {
        y: 200,
        opacity: 0,
      },
      {
        y: 0,
        opacity: 1,
        duration: 1,
      },
      0
    )
    .fromTo(
      '.aivideo .video-list video',
      {
        y: 400,
        opacity: 0,
      },
      {
        y: 0,
        opacity: 1,
        duration: 1,
        stagger: 0.3,
      },
      0.5
    );
  let imgModeTl = gsap.timeline({
    scrollTrigger: {
      trigger: '.m-imgmode',
      start: 'top bottom',
      toggleActions: 'play none none none',
    },
  });
  imgModeTl.fromTo(
    '.m-imgmode .aniText',
    {
      y: 200,
      opacity: 0,
    },
    {
      y: 0,
      opacity: 1,
      duration: 1,
    },
    0
  );
  imgModeTl.fromTo(
    '.m-imgmode .ani',
    {
      y: 400,
      opacity: 0,
    },
    {
      y: 0,
      opacity: 1,
      duration: 1,
      onComplete: () => {
        let tl = gsap.timeline({
          scrollTrigger: {
            trigger: '.m-imgmode .ani',
            start: 'top center',
            toggleActions: 'play none none none',
          },
        });
        tl.fromTo(
          '.m-imgmode .ani .tablet',
          {
            rotateX: 45,
          },
          {
            rotateX: 0,
            duration: 1,
          },
          0
        )
          .to(
            '.m-imgmode .flower_left',
            {
              y: '50%',
              duration: 1,
            },
            0
          )
          .to(
            '.m-imgmode .flower_right',
            {
              y: 0,
              duration: 1,
            },
            0
          );
      },
    },
    0
  );
}

export default animation;
