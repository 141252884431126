import request from '../request/index.js';

function _config(config) {
  if (config.url === undefined) {
    throw Error('missing url');
  }
  return {
    ...config,
    url: '/console/v1/home' + config.url,
  };
}

export const httpSubmit = (data) => {
  return request(
    _config({
      url: '/comment/submit',
      data: {
        Name: data.Name,
        Title: data.Title,
        Email: data.Email,
        Message: data.Message,
      },
      method: 'POST',
    })
  );
};
