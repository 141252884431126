import './style.scss';
import { useEffect } from 'react';

function Home(props) {
  const btnGoDia = () => {
    props.btnGoDia();
  };
  useEffect(() => {}, []);
  return (
    <div className={`homewrap2`}>
      <div className="home">
        <div className="home-l">
          <div className="l-1">
            助力品牌通过智能创意与数据驱动的互动体验，与消费者建立深度连接
          </div>
          <div className="l-2">
            从内容策划到数据分析，我们为您提供全面、智能的解决方案，推动品牌走在数字营销的前沿。
          </div>
          <button className="aniText move-ani" onClick={btnGoDia}>
            立即咨询
          </button>
        </div>
        <div className="home-r"></div>
      </div>
      <div className="home-portrait">
        <div className="home-two">
          <div className="home-l">
            <div className="l-1">
              助力品牌通过智能创意与数据驱动的互动体验，与消费者建立深度连接
            </div>
          </div>
        </div>
        <div className="l-2">
          从内容策划到数据分析，我们为您提供全面、智能的解决方案，推动品牌走在数字营销的前沿。
        </div>
        <button className="aniText move-ani" onClick={btnGoDia}>
          立即咨询
        </button>
        <div className="home-r"></div>
      </div>
    </div>
  );
}

export default Home;
