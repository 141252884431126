import './style.scss';
import { useEffect, useState } from 'react';
import Footer from '../Footer/index';
import NavBar from '../NavBar';
import CaseHome from './caseHome/index.js';
import CaseText from './caseText/index.js';
import CaseSelected from './selectedCase/index.js';
import MyModal from '../MyModal/index.js';

function Case() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const closeOpen = () => {
    setIsModalOpen(false);
  };
  const btnGoDia = () => {
    setIsModalOpen(true);
  };
  useEffect(() => {
    console.log('case');
  }, []);
  return (
    <div style={{ background: 'black' }}>
      <MyModal isModalOpen={isModalOpen} closeOpen={closeOpen} />
      <NavBar btnGoDia={btnGoDia}></NavBar>
      <CaseHome btnGoDia={btnGoDia} />
      <CaseText />
      <CaseSelected />
      <Footer mode={'pc'} btnGoDia={btnGoDia}></Footer>
    </div>
  );
}

export default Case;
