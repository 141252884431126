// 配置 axios
import axios from 'axios';
import RandomString from '../utils/random.js';
const request = axios.create({
  baseURL: window.CONFIG.apiUrl.default,
  timeout: 20000,
  withCredentials: true,
  headers: {
    'X-Page': window.location.href,
  },
});

// 请求拦截器
request.interceptors.request.use(
  (config) => {
    config.headers['X-Request-Id'] = RandomString.generateRandomString();
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 响应拦截器
request.interceptors.response.use(
  (res) => {
    return res.data;
  },
  (error) => {
    const { response } = error;
    return Promise.reject(response.data);
  }
);
export default request;
