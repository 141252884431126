import './components/App/style.scss'
import {Outlet} from 'react-router-dom'

function App() {
    return (
        <Outlet/>
    );
}

export default App;
