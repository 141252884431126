import './style.scss';
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

function NavBar(props) {
  const [anyway, setAnyway] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    // 重置滚动条位置
    window.scrollTo(0, 0);
  }, [location]);
  useEffect(() => {
    if (window.innerHeight > window.innerWidth) {
      setAnyway(true);
    }
    if (window.innerHeight < window.innerWidth) {
      setAnyway(false);
    }
  }, []);
  return (
    <div className="navbar">
      <div className="nav-mask"></div>
      <div className="boxs">
        <img
          className="logo"
          onClick={() => navigate('/index')}
          alt=""
          src={window.ASSETS.home_logo}
          style={{ cursor: 'pointer' }}
        />
        <div className="apikey apikeyleft" onClick={() => navigate('/case')}>
          笔墨案例
        </div>
        <div
          className="apikey api-doc"
          onClick={() => window.open(window.CONFIG.ApiDoc)}
        >
          API文档
        </div>
      </div>
      {!anyway ? (
        <button
          className="move-ani"
          onClick={() => window.open(window.CONFIG.console)}
        >
          立即开始
        </button>
      ) : (
        <button className="move-ani2" onClick={() => props.btnGoDia()}>
          联系我们
        </button>
      )}
    </div>
  );
}

export default NavBar;
