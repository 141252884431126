import './style.scss';
import { useEffect } from 'react';
// import Tooltip from '../../Tooltip/index';
import { Popover } from 'antd';
function CaseText() {
  useEffect(() => {}, []);
  const textList = [
    {
      caseImg:
        'https://bimoai-sh.oss-cn-shanghai.aliyuncs.com/bimoai/console/static/webui/web/lavazza.png',
      text: '自然堂 x LAVAZZA快闪活动：咔嚓一下自然美栀栀',
      logo: 'https://bimoai-sh.oss-cn-shanghai.aliyuncs.com/bimoai/console/static/webui/web/logoa.png',
      cycle: '3-4周',
      helf: 'https://mp.weixin.qq.com/s/xzST6ZIGAY1oXSQW1NF_4A',
      tooltip: '自然堂 | LAVAZZA',
    },
    {
      caseImg:
        'https://bimoai-sh.oss-cn-shanghai.aliyuncs.com/bimoai/console/static/webui/web/chuima.png',
      text: '上海中心垂直马拉松',
      logo: 'https://bimoai-sh.oss-cn-shanghai.aliyuncs.com/bimoai/console/static/webui/web/logob.png',
      cycle: '1-2周',
      helf: 'https://mp.weixin.qq.com/s/kq07pmqRC2fj0_28CT0W4Q',
      tooltip: '上海中心',
    },
    // {
    //   caseImg:
    //     'https://bimoai-sh.oss-cn-shanghai.aliyuncs.com/bimoai/console/static/webui/web/baishe.png',
    //   text: '自然堂x白蛇年终集卡',
    //   logo: 'https://bimoai-sh.oss-cn-shanghai.aliyuncs.com/bimoai/console/static/webui/web/logoc.png',
    //   cycle: '3-4周',
    //   helf: 'https://mp.weixin.qq.com/s/wY0EAhAsWM-RbKPFZQNGxQ',
    //   tooltip: 'CHANDO/白蛇',
    // },
    {
      caseImg:
        'https://bimoai-sh.oss-cn-shanghai.aliyuncs.com/bimoai/console/static/webui/web/xiaoyuanjie.png',
      text: '自然堂AI音乐校园节',
      logo: 'https://bimoai-sh.oss-cn-shanghai.aliyuncs.com/bimoai/console/static/webui/web/logoF.png',
      cycle: '3-4周',
      helf: 'https://mp.weixin.qq.com/s/b7jJ0jXUveRvE_lWg8bERg',
      tooltip: '自然堂',
    },
    {
      caseImg:
        'https://bimoai-sh.oss-cn-shanghai.aliyuncs.com/bimoai/console/static/webui/web/maogeping.png',
      text: '毛戈平NFC礼盒送祝福',
      logo: 'https://bimoai-sh.oss-cn-shanghai.aliyuncs.com/bimoai/console/static/webui/web/logoe.png',
      cycle: '2-3周',
      helf: 'https://mp.weixin.qq.com/s/j2C2oAdYTZhUvE2kHmo5zw',
      tooltip: '毛戈平',
    },
    // {
    //   caseImg:
    //     'https://bimoai-sh.oss-cn-shanghai.aliyuncs.com/bimoai/console/static/webui/web/nianzhongliyu.png',
    //   text: '自然堂年中礼遇季送祝福',
    //   logo: 'https://bimoai-sh.oss-cn-shanghai.aliyuncs.com/bimoai/console/static/webui/web/logoF.png',
    //   cycle: '3-4周',
    //   helf: 'https://mp.weixin.qq.com/s/wY0EAhAsWM-RbKPFZQNGxQ',
    //   tooltip: 'CHANDO',
    // },
    // {
    //   caseImg:
    //     'https://bimoai-sh.oss-cn-shanghai.aliyuncs.com/bimoai/console/static/webui/web/lvyoujie.png',
    //   text: '自然堂旅游节|枕边风博客打榜',
    //   logo: 'https://bimoai-sh.oss-cn-shanghai.aliyuncs.com/bimoai/console/static/webui/web/logoF.png',
    //   cycle: '2-3周',
    //   helf: 'https://mp.weixin.qq.com/s/wY0EAhAsWM-RbKPFZQNGxQ',
    //   tooltip: 'CHANDO',
    // },
    // {
    //   caseImg:
    //     'https://bimoai-sh.oss-cn-shanghai.aliyuncs.com/bimoai/console/static/webui/web/zhenjiu.png',
    //   text: '珍酒芳华主题写真',
    //   logo: 'https://bimoai-sh.oss-cn-shanghai.aliyuncs.com/bimoai/console/static/webui/web/zhenjiulogo.png',
    //   cycle: '3-4周',
    //   helf: 'https://mp.weixin.qq.com/s/wY0EAhAsWM-RbKPFZQNGxQ',
    //   tooltip: '贵州珍酒',
    // },
  ];
  return (
    <div className={`homewraps`}>
      <div className="home">
        <div className="h-t">精选案例</div>
        <div className="h-c">
          {textList.map((item, index) => (
            <div
              className="t-1 t-t"
              key={index}
              onClick={() => {
                window.open(item.helf);
              }}
            >
              <div className="t-t-th">
                <img src={item.caseImg} alt="" />
              </div>
              <div className="t-t-td">
                <div className="p-t">{item.text}</div>
                <div className="p-l">
                  <div className="p-logo">
                    {/* <Tooltip className="tooltip" label={item.tooltip}></Tooltip> */}
                    <Popover
                      placement="top"
                      content={
                        <div
                          className="tooltip-content"
                          style={{ color: '#fff', fontSize: '11px' }}
                        >
                          <div>{item.tooltip}</div>
                        </div>
                      }
                      color=" rgba(60, 60, 60, 0.88)"
                    >
                      {/* <img src={item.logo} alt="" /> */}
                      <div
                        className="p-logo-bg"
                        style={{ backgroundImage: `url(${item.logo})` }}
                      ></div>
                    </Popover>
                  </div>
                  <div className="p-l-t">
                    <div style={{ marginRight: '10px' }}>小程序</div>
                    <div>{item.cycle}</div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default CaseText;
