import React, { useState, useEffect } from 'react';
import { Button, Modal, Form, Input, Space, message } from 'antd';
import './MyModal.scss';
import { httpSubmit } from '../../api/index.js';
import { CopyToClipboard } from 'react-copy-to-clipboard';
const App = (props) => {
  const { isModalOpen, closeOpen } = props;
  const [form] = Form.useForm();
  const [anyway, setAnyway] = useState(false);
  const handleCancel = () => {
    form.resetFields();
    closeOpen();
  };

  useEffect(() => {
    if (window.innerHeight > window.innerWidth) {
      setAnyway(true);
    }
    if (window.innerHeight < window.innerWidth) {
      setAnyway(false);
    }
  }, []);
  const SubmitButton = ({ form }) => {
    const [submittable, setSubmittable] = React.useState(false);
    const values = Form.useWatch([], form);
    React.useEffect(() => {
      form
        .validateFields({
          validateOnly: true,
        })
        .then(() => setSubmittable(true))
        .catch(() => setSubmittable(false));
    }, [form, values]);
    return (
      <Button
        type="primary"
        htmlType="submit"
        className="btn-submit"
        disabled={!submittable}
      >
        发送
      </Button>
    );
  };
  const onFinish = async (values) => {
    console.log('Success:', values);
    try {
      const res = await httpSubmit(values);
      console.log(res);
      form.resetFields();
      closeOpen();
      message.open({
        type: 'success',
        content: '发送成功请等待回复',
      });
    } catch (error) {
      console.log(error);
      if (error.Code === 400) {
        message.open({
          type: 'error',
          content: '请正确填写邮箱！',
        });
      }
    }
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  const validateMessageLength = (rule, value) => {
    if (value && value.length > 500) {
      return Promise.reject('留言内容不能超过500个字符');
    }
    return Promise.resolve();
  };
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return (
    <>
      <Modal
        open={isModalOpen}
        centered
        footer={null}
        width={'1120px'}
        onCancel={handleCancel}
        clearOnDestroy={true}
      >
        <div className="my-model">
          <div className="my-model-l">
            {/* <img
              src="https://bimoai-sh.oss-cn-shanghai.aliyuncs.com/bimoai/console/static/webui/web/lianxiwomenzuotu.png"
              alt=""
            /> */}
            <p className="p1">联系我们</p>
            <p className="p2">开启AIGC创意之旅</p>
            <p className="p3">我们将在两个工作日内联系您</p>
          </div>
          <div className="my-model-r">
            <Form
              form={form}
              name="validateOnly"
              layout="vertical"
              autoComplete="off"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              size={'large'}
            >
              <Form.Item
                name="Name"
                label="怎么称呼您："
                rules={[
                  {
                    required: true,
                    message: '请输入您的称呼',
                  },
                ]}
              >
                <Input maxLength={32} />
              </Form.Item>
              <Form.Item
                name="Title"
                label="您是什么职位/行业："
                rules={[
                  {
                    required: true,
                    message: '请输入您的职位/行业',
                  },
                ]}
              >
                <Input maxLength={32} />
              </Form.Item>
              <Form.Item
                name="Email"
                label="E-mail："
                rules={[
                  {
                    required: true,
                    message: '请输入您的邮箱',
                  },
                  {
                    pattern: emailRegex,
                    message: '请输入有效的邮箱地址',
                  },
                ]}
              >
                <Input maxLength={64} />
              </Form.Item>
              <Form.Item
                name="Message"
                label="给我们留下意见："
                rules={[
                  {
                    required: true,
                    message: '请输入您的留言',
                  },
                  {
                    validator: validateMessageLength,
                  },
                ]}
              >
                <Input.TextArea
                  maxLength={500}
                  autoSize={{ minRows: 3, maxRows: 3 }}
                />
              </Form.Item>
              <Form.Item>
                <Space style={{ width: '100%' }}>
                  {anyway ? (
                    <div className="Space-item-h5">
                      <SubmitButton form={form}>Submit</SubmitButton>
                      <div style={{ marginTop: '18px' }}>
                        <p>或者联系邮箱：</p>
                        {/* <p>bimoai@126.com</p> */}
                        <CopyToClipboard
                          text={'bimoai@126.com'}
                          onCopy={() => {
                            message.success('已拷贝邮箱');
                          }}
                        >
                          <span
                            style={{
                              cursor: 'pointer',
                              textDecoration: 'underline',
                              userSelect: 'text',
                            }}
                          >
                            bimoai@126.com
                          </span>
                        </CopyToClipboard>
                      </div>
                    </div>
                  ) : (
                    <div className="Space-item">
                      <div>
                        <p>您也可以通过邮箱随时联系我们</p>
                        {/* <p style={{ userSelect: 'text' }}>bimoai@126.com</p> */}
                        <CopyToClipboard
                          text={'bimoai@126.com'}
                          onCopy={() => {
                            message.success('已拷贝邮箱');
                          }}
                        >
                          <span
                            style={{
                              cursor: 'pointer',
                              textDecoration: 'underline',
                              userSelect: 'text',
                            }}
                          >
                            bimoai@126.com
                          </span>
                        </CopyToClipboard>
                      </div>
                      <SubmitButton form={form}>Submit</SubmitButton>
                    </div>
                  )}
                </Space>
              </Form.Item>
            </Form>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default App;
